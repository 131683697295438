
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { add } from 'ionicons/icons';

// components
import { IonPage, IonContent, IonGrid, IonSpinner, IonList, } from '@ionic/vue';
import OrderItem from "@/components/order/OrderItem.vue";

// API services
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'OrderListPage',
  components: { IonContent, IonPage, IonGrid, IonSpinner, IonList, OrderItem, },
  setup() {
    // methods or filters
    const { t } = useI18n();
    const store = useStore();
    const { formatDate } = utils();

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingAppUserData);
    const orders = computed(() => store.state.userOrders);

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add,

      // variables
      loading, orders,

      // methods
      t, formatDate,
    }
  }
}
