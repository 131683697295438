import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_order_item = _resolveComponent("order-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        title: $setup.t('myOrders'),
        parentPath: "/home"
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          ($setup.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_spinner)
              ]))
            : (_openBlock(), _createBlock(_component_ion_grid, {
                key: 1,
                fixed: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.orders, (order) => {
                        return (_openBlock(), _createBlock(_component_order_item, {
                          key: order.id,
                          order: order
                        }, null, 8, ["order"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}