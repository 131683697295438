import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-507f72d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    "router-link": `/orders/${$props.order.id}`,
    button: "",
    detail: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_text, { color: "secondary" }, {
            default: _withCtx(() => [
              _createElementVNode("h3", null, [
                _createTextVNode(_toDisplayString($setup.t('OrderPage.order')) + " ", 1),
                _createElementVNode("b", null, "#" + _toDisplayString($props.order.code), 1)
              ])
            ]),
            _: 1
          }),
          ($props.order.createdAt)
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString($setup.formatDate($props.order.createdAt)), 1))
            : _createCommentVNode("", true),
          _createElementVNode("p", null, [
            _createTextVNode(_toDisplayString($setup.t('OrderPage.total')), 1),
            _createElementVNode("b", {
              class: _normalizeClass({ 'red-text': $setup.getOrderStatusColor($props.order.status) == 'danger'})
            }, "HK$" + _toDisplayString($props.order.totalPrice.toLocaleString()), 3)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_badge, {
        slot: "end",
        color: $setup.getOrderStatusColor($props.order.status)
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.t($props.order.status)), 1)
        ]),
        _: 1
      }, 8, ["color"])
    ]),
    _: 1
  }, 8, ["router-link"]))
}