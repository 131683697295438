
// components
import { IonItem, IonThumbnail, IonLabel, IonBadge, IonButton, IonText, } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';

export default {
  props: [
    "order"
  ],
  components: { IonItem, IonThumbnail, IonLabel, IonBadge, IonButton, IonText, },
  setup() {
    const { t } = useI18n();
    const { getLocalizedStr, getOrderStatusColor, formatDate, openImageModal } = utils();

    return {
      // methods
      t, getLocalizedStr, getOrderStatusColor, formatDate, openImageModal,
    }
  }
}
